"use client";
import { LoginForm } from "@/components/organisms/login-form";

export default function LoginPage() {
  return (
    <main className="container flex min-h-screen flex-col items-center justify-between p-24">
      <div className="w-96">
        <h3 className="text-2xl p-2 font-semibold text-center">
          Sign in to your account
        </h3>
        <LoginForm />
      </div>
    </main>
  );
}
